// *******************************
// USE FOR INSERTING DEV ONLY BITS
// *******************************
import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

const DevTest = function ({ type }) {
  let staging = false;
  let env = '';

  if (process.env.GATSBY_BRANCH === "staging") {
    staging = true;
    env = "staging";
  }

  const style = {
    position: "fixed",
    bottom: 0,
    left: 0,
    fontSize: '8px',
    color: '#fff',
    backgroundColor: '#ccc',
    padding: '0 5px',
    zIndex: 999
  };

  console.log(type);

  if (!staging || !type) {
    return false;
  } else if (type === 'no-robots') {
    return (
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    );
  } else {
    return <div style={style}>{env}</div>;
  }
};

export default DevTest;

DevTest.propTypes = {
  type: PropTypes.string,
};