import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
// import SiteNavigation from "../components/SiteNavigation";
import PageTransition from "../globals/PageTransition";
import DevTest from "../globals/DevTest";

import "../styles/index.sass";

// console.log("GATSBY_BRANCH: " + process.env.GATSBY_BRANCH);
// console.log("GATSBY_NETLIFY_ENV: " + process.env.GATSBY_NETLIFY_ENV);

import ConsoleBlocker from "../utils/console";
let activeEnv = process.env.ACTIVE_ENV || process.env.NODE_ENV || "development";
if (activeEnv !== "development") {
  ConsoleBlocker.run();
}

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    speed: 800
  });
}

class Layout extends React.Component {
  componentDidMount() {
    // GLOBAL JS FILES...
    // -------------------
    // Polyfill for scrolling
    require("intersection-observer");
    // Defines device
    const device = require("current-device").default;
  }

  render() {
    return (
      <>
        <DevTest type="tag" />
        {/* <SiteNavigation location={this.props.location}/> */}
        <PageTransition location={this.props.location}>
          {this.props.children}
        </PageTransition>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object
};

export default Layout;
